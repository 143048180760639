<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
).px-4
  FormAdd(slot="AddBlock" actionType="add")
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SailorCertificateEti',
  components: {
    FormAdd: () => import('./Form.vue')
  },
  data () {
    return {
      headers: [
        { value: 'number', text: this.$t('number'), cellClass: 'tableNumber', sortable: false },
        { value: 'ntz', text: this.$t('passportIssued'), cellClass: 'ellipsis', sortable: false },
        { value: 'course_training', text: this.$t('course'), cellClass: 'ellipsis', sortable: false },
        { value: 'date_start', text: this.$t('dateIssue'), cellClass: 'tableDate', sortable: true },
        { value: 'date_end', text: this.$t('dateEnd'), cellClass: 'tableDate', sortable: true },
        { value: 'status_document', text: this.$t('status'), cellClass: 'tableStatus', sortable: true },
        { value: 'event', text: this.$t('actions'), class: 'mw-0 position-relative', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: ({ id }) => this.$router.push({ name: 'certification-certificates-info', params: { ...this.$route.params, documentID: id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.sailor.certificateEti,
      isLoading: state => state.sailor.isLoading
    })
  },
  methods: {
    ...mapActions(['getListCertificateEti']),
    async getData (params) {
      await this.getListCertificateEti({ ...this.$route.params, params })
    }
  }
}
</script>
